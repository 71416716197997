<template>
  <div>
    <div class="py-12 md:py-24">
      <Container>
        <h2 class="h1">See your data</h2>
        <template v-if="numId">
          <div class="max-w-prose">
            <p>
              This is the machine-readable data that's available for
              <code>{{ numId }}</code
              >.
            </p>
          </div>
          <NumIdLookup :numId="numId" />
        </template>
        <div class="max-w-prose" v-else>
          <p>
            At NUM, we're constantly crawling the open web to find publicly
            accessible data and packaging that data into machine readable NUM
            records.
          </p>
          <p><strong>See what data we have for your domain:</strong></p>
          <Form
            class="text-gray-700"
            v-slot="{ errors }"
            :on-submit="submitForm"
          >
            <ValidationProvider
              name="Domain name"
              rules="required|domain"
              vid="companyApiInput"
              class="flex max-w-screen-sm"
            >
              <Input
                v-model="domain"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                class="flex-1 mb-0 border-none"
                id="domain-input"
                input-class="p-1"
                placeholder="Domain name"
                :show-label="false"
                type="input"
              />
              <Button
                class="mb-0"
                :disabled="domain.length === 0"
                type="submit"
                value="Lookup"
              />
            </ValidationProvider>
            <FormError
              v-if="
                errors &&
                errors['companyApiInput'] &&
                errors['companyApiInput'].length
              "
              :dark="true"
              class="mt-2"
            >
              {{ errors["companyApiInput"][0] }}
            </FormError>
          </Form>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Container,
  Form,
  FormError,
  Input,
} from "@num/component-library";
import { ValidationProvider } from "vee-validate";
import Vue from "vue";

import NumIdLookup from "@/views/Claim/NumIdLookup.vue";

import router from "@/router";

export default Vue.extend({
  components: {
    Button,
    NumIdLookup,
    Container,
    Form,
    FormError,
    Input,
    ValidationProvider,
  },
  props: {
    numId: String,
  },
  data() {
    return {
      domain: "",
    };
  },
  methods: {
    submitForm() {
      router.push({ params: { numId: this.domain } });
    },
  },
});
</script>
