<template>
  <div class="last:mb-0">
    <h1 class="h3 text-gray-400 mb-2">NUM Tasks</h1>
    <h2 class="h2">How to open NUM Tasks Extension</h2>
    <ol>
      <li>
        Use the keyboard shortcut to open the browser devtools:
        <ul class="mb-2 ml-6 list-disc">
          <li>
            Mac: <code>CMD <span class="fs-4 relative top-0.5">⌘</span></code> +
            <code
              >OPTION (or ALT)
              <span class="fs-4 relative top-0.5">⌥</span></code
            >
            +
            <code>I</code>
          </li>
          <li>
            Windows:
            <code>CTRL</code> +
            <code
              >SHIFT
              <ion-icon
                name="arrow-up-outline"
                class="relative top-0.5"
              ></ion-icon
            ></code>
            +
            <code>I</code>
          </li>
        </ul>
      </li>
      <li>
        Find the <code>NUM Tasks</code> extension in the tabs of the new window
        that has opened up
      </li>
      <li>
        If you can't see it, it may be hidden behind the double chevron
        (<code>&gt;&gt;</code>), click that
      </li>
      <li>
        <em
          >Optional: Click and drag the tab to rearrange it's position in the
          tab bar</em
        >
      </li>
      <li>
        If there's a console window showing below the
        <code>NUM Tasks</code> extension, press the <code>esc</code> key on your
        keyboard to hide it
      </li>
      <li>That's it! You can now log in and begin tasks</li>
    </ol>
    <hr />
    <p>
      <em>
        If you have any issues or feedback you can contact us on
        <a href="mailto:contact@numserver.com">contact@numserver.com</a>.</em
      >
    </p>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  metaInfo: {
    title: "NUM Tasks Start Page",
  },
});
</script>
