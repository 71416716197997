<template>
  <div class="last:mb-0">
    <div
      v-for="item in filteredItems"
      :key="item.key"
      class="mb-2 last:mb-0"
      :class="classes(item)"
    >
      <template v-if="item.value && isArrayOrObject(item.value)">
        <h4 v-if="!Array.isArray(json)">{{ item.key }}</h4>
        <NumIdResult
          :is-grouped="shouldGroup(item)"
          :json="item.value"
          :converting="converting"
          :is-root="false"
        />
      </template>
      <h4 v-else-if="item.key === 'HEADER'">{{ item.value }}</h4>
      <div v-else>
        <template v-if="item.key && !Array.isArray(json)">
          {{ item.key }}:
        </template>
        <strong v-if="item.value" v-html="item.value" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "NumIdResult",
  props: {
    converting: Boolean,
    isGrouped: Boolean,
    isRoot: Boolean,
    json: [Array, Object, Number, String],
  },
  computed: {
    filteredItems() {
      if (!this.json) return;
      const jsonKeys = Object.keys(this.json);
      const filteredJsonKeys = this.isRoot
        ? jsonKeys.filter((k) => !k.startsWith("@"))
        : jsonKeys;
      const jsonItems = filteredJsonKeys.map((k) => ({
        key: k,
        value: this.json[k],
      }));
      return jsonItems;
    },
  },
  methods: {
    classes(item) {
      if (this.shouldGroup(item)) return "module-editor-group";
    },
    isArrayOrObject(item) {
      return item && typeof item === "object";
    },
    shouldGroup(item) {
      const valueIsString = typeof item.value === "string";
      return !valueIsString;
    },
  },
});
</script>
