<template>
  <div>
    <div class="pb-12 md:pb-24">
      <Container>
        <div class="max-w-prose mx-auto">
          <h1 class="h3 text-gray-400 mb-2">So long, middlemen</h1>
          <h2 class="h1">Take control of your data</h2>
          <p>
            You shouldn't be at the mercy of the gatekeepers of the web to
            provide useful information to your customers. NUM is a single,
            authoritative, open repository for all of your company data. Publish
            your data using NUM and take back control.
          </p>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { Container } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Container,
  },
});
</script>
