import log from "loglevel";
import { createClient, DoHResolver } from "num-client";

import { STAGE } from "@/app.config";

const getLogLevel = () => {
  switch (STAGE) {
    case "local":
      return "warn";
    case "test":
      return "debug";
    case "staging":
      return "warn";
    default:
      return "silent";
  }
};
const logLevel = getLogLevel();
log.setLevel(logLevel);

const CUSTOM_RESOLVERS = [
  new DoHResolver("Cloudflare", "https://cloudflare-dns.com/dns-query"),
  new DoHResolver("NUM", "https://api.apps.num.uk/v1/doh/dns-query"),
];

// Pass in an optional array of DoHResolvers now instead of a `dnsClient`
export const numClient = createClient(CUSTOM_RESOLVERS);

if (STAGE === "test" || STAGE === "staging") {
  numClient.setModuleEnv(STAGE);
} else if (STAGE === "local") {
  numClient.setModuleEnv("test");
}
