<template>
  <div>
    <Hero class="bg-dark-gray-gradient-angle-bottom">
      <h1 class="fs-0">
        Claim <code v-if="numId" class="is-dark">{{ numId }}</code
        ><template v-else>your NUM ID</template>
      </h1>
      <p>
        Claim your {{ numIdType || "NUM ID" }} and take control of your
        machine-readable data.
      </p>
    </Hero>
    <DataForNumId :num-id="numId" />
    <NumExplainer />
    <WhyNumServer :claim-url="claimUrl" :num-id="numId" />
  </div>
</template>

<script>
import { Hero } from "@num/component-library";
import Vue from "vue";

import DataForNumId from "@/views/Claim/DataForNumId.vue";
import NumExplainer from "@/components/NumExplainer.vue";
import WhyNumServer from "@/components/WhyNumServer.vue";

import { NUMSERVER_APP_URL } from "@/app.config";

export default Vue.extend({
  components: {
    DataForNumId,
    Hero,
    NumExplainer,
    WhyNumServer,
  },
  computed: {
    claimUrl() {
      if (!this.numId) return "https://app.numserver.com/register";
      return `${NUMSERVER_APP_URL}/claim/${this.numIdType}/${this.numId}`;
    },
    numId() {
      return this.$route.params.numId;
    },
    numIdType() {
      if (!this.numId) return null;
      return this.numId.includes("@") ? "email" : "domain";
    },
  },
});
</script>
