<template>
  <div>
    <Hero class="bg-dark-gray-gradient-angle-bottom">
      <h1 class="fs-0">Get in touch</h1>
    </Hero>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose">
          <p>
            If you've got any questions about the NUM Server, thoughts on NUM, a
            module to suggest or you're interested in working with us, we'd love
            to hear from you.
          </p>
          <p>
            You can reach us at
            <a href="mailto:contact@numserver.com">contact@numserver.com</a>.
          </p>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { Container, Hero } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Container,
    Hero,
  },
});
</script>
