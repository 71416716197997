<template>
  <div>
    <NumServerGlobalNav />
    <div class="main-body">
      <slot />
    </div>
    <NumServerGlobalFooter />
  </div>
</template>

<script>
import Vue from "vue";

import NumServerGlobalFooter from "@/components/NumServerGlobalFooter.vue";
import NumServerGlobalNav from "@/components/NumServerGlobalNav.vue";

export default Vue.extend({
  components: {
    NumServerGlobalNav,
    NumServerGlobalFooter,
  },
});
</script>

<style lang="scss">
.main-body {
  & > :last-child {
    &::after {
      @apply h-32;
      content: "";
      display: block;
    }
  }
}
</style>
