<template>
  <div class="flex items-center min-h-screen py-8">
    <Container class="w-full max-w-prose">
      <NumServerLogo class="w-12 h-12 mb-4 text-gray-400" />
      <slot />
    </Container>
  </div>
</template>

<script>
import { Container } from "@num/component-library";
import Vue from "vue";

import NumServerLogo from "@/assets/images/logos/numserver.svg";

export default Vue.extend({
  components: {
    Container,
    NumServerLogo,
  },
});
</script>
