<template>
  <div>
    <h2>
      Data currently published for <code>{{ numId }}</code>
    </h2>
    <p
      class="
        inline-flex
        flex-wrap
        items-center
        px-4
        py-2
        rounded-full
        bg-gray-500
        text-white
        cursor-pointer
      "
      @click="clearNumId"
    >
      <ion-icon
        name="close-circle-outline"
        title="Clear icon"
        aria-label="Clear icon"
        class="mr-1"
      />
      <small>Lookup a different domain name or email address</small>
    </p>
    <div
      class="flex flex-wrap border-t border-black"
      v-if="result && !isEmptyObject(result)"
    >
      <div class="w-full flex md:w-3/5 lg:w-2/3">
        <div class="flex-1 overflow-x-scroll p-8 bg-gray-900 text-gray-100">
          <NumIdResult
            :is-root="true"
            :json="filteredResult"
            class="is-dark"
            v-if="showResult"
          />
        </div>
      </div>
      <div class="w-full flex md:w-2/5 lg:w-1/3">
        <div class="flex-1 p-8 bg-gray-800 text-white" v-if="result">
          <h2 class="fs-3">Lookup info</h2>

          <ul class="text-gray-400">
            <li v-if="telephoneCount > 0">
              <strong class="fs-4 text-white">{{ telephoneCount }}</strong>
              telephone number<template v-if="telephoneCount !== 1">s</template>
            </li>
            <li v-if="socialProfileCount > 0">
              <strong class="fs-4 text-white">{{ socialProfileCount }}</strong>
              social profile<template v-if="socialProfileCount !== 1"
                >s</template
              >
            </li>
            <li v-if="imageCount > 0">
              <strong class="fs-4 text-white">{{ imageCount }}</strong>
              image<template v-if="imageCount !== 1">s</template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="p-8 bg-gray-900 text-gray-500 last:mb-0">
      <h3>
        Gathering data for <code>{{ numId }}</code
        >&hellip;
      </h3>
    </div>
    <div v-else class="p-8 bg-gray-900 text-gray-500 last:mb-0">
      <h3>
        No results found for <code>{{ numId }}</code>
      </h3>
    </div>
  </div>
</template>

<script>
import { utilsTypeOf } from "@num/component-library";
import moment from "moment";
import { parseNumUri, PositiveInteger } from "num-client";
import ContactsModuleHelper from "num-js-modules/src/contacts";
import Vue from "vue";
import "vue-json-pretty/lib/styles.css";

import NumIdResult from "@/views/Claim/NumIdResult.vue";

import { numClient } from "@/numLookup";
import router from "@/router";

const { isEmptyObject } = utilsTypeOf;

const stringToSentenceCase = function (string) {
  var rg = /(^\w{1}|\.\s*\w{1})/gi;
  return string.replace(rg, function (toReplace) {
    return toReplace.toUpperCase();
  });
};

// src: https://stackoverflow.com/a/41072596/827129
const formatObjectKeys = function (input) {
  if (typeof input !== "object") return input;
  if (Array.isArray(input)) return input.map(formatObjectKeys);
  return Object.keys(input).reduce(function (newObj, key) {
    let val = input[key];
    let newVal =
      typeof val === "object" && val !== null ? formatObjectKeys(val) : val;

    // if an entry exists in `swapKeys` swap the key, or use the existing one
    const swappedKey = swapKeys[key] !== undefined ? swapKeys[key] : key;

    const sentenceCasedKey = stringToSentenceCase(
      swappedKey.replace(/_/g, " "),
    );
    newObj[sentenceCasedKey] = newVal;
    return newObj;
  }, {});
};

const ignoreKeys = [
  "@n",
  "action",
  "available_now",
  "controller",
  "description_default",
  "icon",
  "method_type",
  "method_display_name",
  "methods",
  "object_type",
  "object_display_name",
  "original",
];

const swapKeys = {
  "@L": "link",
};

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const formatDate = (date) => {
  const momentDate = moment(date);
  const numberedDayOfWeek = momentDate.weekday();
  const isToday = moment().isSame(momentDate, "day");
  const isTomorrow = moment().add(1, "days").isSame(momentDate, "day");
  if (isToday) return "Today";
  if (isTomorrow) return "Tomorrow";
  return daysOfWeek[numberedDayOfWeek];
};

const filterResult = function (result) {
  if (typeof result !== "object") return result;
  if (Array.isArray(result)) return result.map(filterResult);
  if (result.method_type === "address") {
    result = {
      [result.method_display_name]: [
        ...result.lines,
        result.postcode,
        result.country,
      ],
    };
  } else if (result.method_type !== "link") {
    result = {
      HEADER: result.method_display_name,
      [result.description || ""]: result.controller
        ? result.action
        : result.value,
      ...result,
    };
    result.hours?.days.forEach((day) => {
      result.hours[formatDate(day.date)] = day.available;
    });
    delete result.hours?.days;
    delete result.description;
    delete result.value;
  }
  return Object.keys(result).reduce(function (newObj, key) {
    const val = result[key];

    if (val && !ignoreKeys.includes(key)) {
      const newVal =
        typeof val === "object" && val !== null ? filterResult(val) : val;
      newObj[key] = newVal;
    }

    return formatObjectKeys(newObj);
  }, {});
};

export default Vue.extend({
  components: {
    NumIdResult,
  },
  props: {
    numId: String,
  },
  data() {
    return {
      isEmptyObject,
      loading: false,
      modlResult: false,
      result: null,
      showResult: false,
      submittedInput: "",
    };
  },
  computed: {
    filteredResult() {
      return filterResult(this.result);
    },
    imageCount() {
      return this.result?.images?.length || 0;
    },
    socialProfileCount() {
      return this.result?.contacts?.filter((c) => c.controller)?.length || 0;
    },
    telephoneCount() {
      return this.getTelephoneCountFromContacts(this.result?.contacts);
    },
    vueJsonPrettyDeep() {
      const depthIfBigResult = 4;
      return JSON.stringify(this.result).length > 5000
        ? depthIfBigResult
        : undefined;
    },
  },
  watch: {
    numId: {
      handler(newVal) {
        if (newVal) {
          this.submittedInput = this.numId;
          this.numClientLookup();
        }
      },
      immediate: true,
    },
    result(newVal) {
      if (newVal && !isEmptyObject(newVal)) {
        setTimeout(() => (this.showResult = true), 50);
      } else {
        setTimeout(() => (this.showResult = false), 50);
      }
    },
  },
  methods: {
    clearNumId() {
      router.push({ params: { numId: null } });
    },
    getTelephoneCountFromContacts(contacts) {
      let count = 0;

      if (contacts) {
        for (const contact of contacts) {
          if (contact.method_type === "link")
            count += this.getTelephoneCountFromContacts(
              contact?.numObject?.contacts,
            );
          else if (contact.method_type === "telephone") count += 1;
        }
      }

      return count;
    },
    handleAnyResponse(result) {
      this.loading = false;
      this.result = result;
    },
    numClientLookup() {
      let numUri;
      this.result = null;
      this.modlResult = null;

      // validate NUM URI before continuing and throw error if fails
      try {
        const moduleId = 1;
        numUri = parseNumUri(this.submittedInput.trim());
        numUri = numUri.withPort(new PositiveInteger(moduleId));
      } catch (e) {
        this.numUriError = e;
        this.loading = false;
        return;
      }

      this.loading = true;
      this.numUriError = null;

      const ctx = numClient.createContext(numUri);
      this.setModuleVersions(numUri, ctx);
      numClient
        .retrieveNumRecord(ctx)
        .then((result) => {
          const json = ContactsModuleHelper.transform(
            JSON.parse(result),
            ctx.userVariables,
            null,
          ).numObject;
          this.handleAnyResponse(json);
          this.modlResult = ctx.result;
        })
        .catch((error) => {
          this.handleAnyResponse(error);
        });
    },
    setModuleVersions(numUri, ctx) {
      if (numUri.port.n === 1) ctx.setTargetExpandedSchemaVersion("2");
    },
  },
});
</script>

<style lang="scss">
.module-editor-group {
  padding: 1.25rem;
  margin-bottom: 1rem;
  background-color: rgba(0, 62, 125, 0.05);

  .is-dark & {
    background-color: theme("colors.gray.100-05");
  }
}

.vjs-key {
  @apply mr-1;
  @apply text-gray-300;
}

.vjs-value__string {
  @apply text-blue-400;
}

.vjs-value__null {
  @apply text-gray-500;
}

.vjs-comment {
  @apply text-gray-500;
}

.vjs-tree__node.is-highlight,
.vjs-tree__node:hover {
  @apply bg-gray-800;
}

.vjs-tree__node .vjs-tree__indent.has-line {
  @apply border-gray-700;
}

.vjs-tree__brackets {
  @apply text-gray-500;
}
</style>
