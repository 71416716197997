import Vue from "vue";
import VueRouter from "vue-router";

import Minimal from "@/layouts/Minimal";

import About from "@/views/About.vue";
import Claim from "@/views/Claim.vue";
import Contact from "@/views/Contact.vue";
import Home from "@/views/Home.vue";
import Pricing from "@/views/Pricing.vue";
import TasksStart from "@/views/Tasks/Start.vue";
import Terms from "@/views/Terms.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/claim/:numId?",
    name: "claim",
    component: Claim,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/tasks/start",
    name: "tasks-start",
    component: TasksStart,
    meta: { layout: Minimal },
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
