<template>
  <Hero class="bg-dark-gray-gradient-angle-bottom">
    <h1 class="fs-0">Is Your Business Machine Friendly?</h1>
    <p>The way your customers find your information is changing</p>
  </Hero>
</template>

<script lang="ts">
import { Hero } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  name: "HomeHero",
  components: {
    Hero,
  },
});
</script>
