<template>
  <GlobalFooter site="numserver.com" class="num-uk-global-footer">
    <template v-slot:col-2>
      <h2>Product</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">Overview</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'claim' }">Claim</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'about' }">About</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'pricing' }">Pricing</router-link>
        </li>
      </ul>
    </template>
    <template v-slot:col-3>
      <h2>Resources</h2>
      <ul>
        <li>
          <router-link :to="{ name: 'contact' }">Contact</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'terms' }">Terms</router-link>
        </li>
      </ul>
    </template>
  </GlobalFooter>
</template>

<script lang="ts">
import { GlobalFooter } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    GlobalFooter,
  },
});
</script>

<style lang="scss">
.num-uk-global-footer {
  &.num-uk-global-footer {
    --global-footer-theme-rgb: 31, 41, 55;
    background-color: transparent;
  }
}
</style>
