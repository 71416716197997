var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"last:mb-0"},[_c('h1',{staticClass:"h3 text-gray-400 mb-2"},[_vm._v("NUM Tasks")]),_c('h2',{staticClass:"h2"},[_vm._v("How to open NUM Tasks Extension")]),_c('ol',[_c('li',[_vm._v(" Use the keyboard shortcut to open the browser devtools: "),_c('ul',{staticClass:"mb-2 ml-6 list-disc"},[_vm._m(0),_c('li',[_vm._v(" Windows: "),_c('code',[_vm._v("CTRL")]),_vm._v(" + "),_c('code',[_vm._v("SHIFT "),_c('ion-icon',{staticClass:"relative top-0.5",attrs:{"name":"arrow-up-outline"}})],1),_vm._v(" + "),_c('code',[_vm._v("I")])])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_vm._v("That's it! You can now log in and begin tasks")])]),_c('hr'),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v(" Mac: "),_c('code',[_vm._v("CMD "),_c('span',{staticClass:"fs-4 relative top-0.5"},[_vm._v("⌘")])]),_vm._v(" + "),_c('code',[_vm._v("OPTION (or ALT) "),_c('span',{staticClass:"fs-4 relative top-0.5"},[_vm._v("⌥")])]),_vm._v(" + "),_c('code',[_vm._v("I")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v(" Find the "),_c('code',[_vm._v("NUM Tasks")]),_vm._v(" extension in the tabs of the new window that has opened up ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v(" If you can't see it, it may be hidden behind the double chevron ("),_c('code',[_vm._v(">>")]),_vm._v("), click that ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('em',[_vm._v("Optional: Click and drag the tab to rearrange it's position in the tab bar")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v(" If there's a console window showing below the "),_c('code',[_vm._v("NUM Tasks")]),_vm._v(" extension, press the "),_c('code',[_vm._v("esc")]),_vm._v(" key on your keyboard to hide it ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('em',[_vm._v(" If you have any issues or feedback you can contact us on "),_c('a',{attrs:{"href":"mailto:contact@numserver.com"}},[_vm._v("contact@numserver.com")]),_vm._v(".")])])
}]

export { render, staticRenderFns }