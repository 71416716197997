<template>
  <div>
    <RoundedBorder class="text-gray-300" />
    <div class="bg-gray-300 py-12 md:py-24">
      <div class="max-w-screen-2xl mx-auto px-4 sm:px-8">
        <div class="flex flex-col lg:flex-row lg:items-center -mx-4 -my-6">
          <div class="lg:w-1/2 xl:w-3/5 order-last lg:order-first px-4 py-6">
            <div class="box-border-shadow p-0 mr-4">
              <div class="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/390463971?texttrack=en"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div class="bg-gray-500 text-white p-4">
                <ToggleToShow
                  class="fs-6"
                  toggle-text="Click to show technical notes on video."
                >
                  <p>
                    Technical notes on video: We've simplified terminal input,
                    terminal output and processes for demonstration purposes.
                    We've skipped the pre-cursor to the DNS (the
                    <a
                      href="https://en.wikipedia.org/wiki/Hosts_(file)"
                      target="_blank"
                      >hosts file</a
                    >) to keep the history of name resolution brief and make the
                    explainer more approachable for a non-technical audience.
                  </p>
                </ToggleToShow>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 xl:w-2/5 px-4 py-6 last:mb-0">
            <h1 class="h3 text-gray-500 mb-2">What is NUM?</h1>
            <h2 class="h1">A new way to store and retrieve data</h2>
            <p>
              NUM is like the Web but for machines. Websites are built for
              browsing but are an inefficient way to find precise pieces of data
              like a telephone number, address and more. NUM makes useful data
              machine-readable so it can be built into devices, apps and
              services to make your life easier.
            </p>

            <p>
              NUM is built on top of the Domain Name System (DNS) – a system we
              all use every day. Watch the 90-second explainer to find out more.
            </p>
          </div>
        </div>
      </div>
    </div>
    <RoundedBorder class="text-gray-300" style="transform: scaleY(-1)" />
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import Vue from "vue";

import RoundedBorder from "@/assets/images/roundedBorder.svg";

import ToggleToShow from "@/components/ToggleToShow.vue";

export default Vue.extend({
  name: "NumExplainer",
  components: {
    ToggleToShow,
    RoundedBorder,
  },
});
</script>

<style lang="scss">
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
