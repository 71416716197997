<template>
  <div>
    <Hero class="bg-dark-gray-gradient-angle-bottom">
      <h1 class="fs-0">About Us</h1>
    </Hero>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose">
          <p>
            The NUM Server provides a simple, user-friendly way to adopt NUM for
            a domain name or email address. Records for some modules are
            automatically populated for domain names with public website
            content. This helps to make
            <a href="https://www.numprotocol.com/" target="_blank"
              >the NUM protocol</a
            >
            useful from launch.
          </p>
          <p>The NUM Server is part of NUM Technology Ltd.</p>
          <p>
            You can read more about NUM Technology Ltd on the
            <a href="https://num.uk/about" target="_blank">NUM website</a>.
          </p>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { Container, Hero } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Container,
    Hero,
  },
});
</script>
