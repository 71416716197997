<template>
  <div>
    <Hero class="bg-dark-gray-gradient-angle-bottom">
      <h1 class="fs-0">NUM Server Terms</h1>
    </Hero>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose">
          <p>
            These are the terms on which NUM Server Ltd (“We”, “us”, “our”),
            make the NUM Server available to you.
          </p>
          <ol class="main">
            <li>
              <strong>NUM Server</strong>
              <ol>
                <li>
                  We will operate a DNS name server (the “NUM Server”) for (i)
                  the Hosted NUM Zone, serving NUM records for any NUM ID you
                  have successfully claimed; and (ii) any Independent NUM Zone
                  that you have delegated to us, subject to the following
                  constraints:
                  <ol>
                    <li>
                      Only Managed records marked as “Online” will be served, so
                      for example Managed records marked as “Suspended” or
                      Managed records you have marked as “Offline” will not be
                      served.
                    </li>
                    <li>
                      In any event, no more than the Record Limit of Hosted
                      Records will be served. Where there are more Hosted NUM
                      Records than the Record Limit, the NUM Server will serve
                      the oldest records up to the Record Limit.
                    </li>
                  </ol>
                </li>
                <li>
                  Our duty extends only to operation of a DNS name server and
                  not to ensure that DNS records propagate across the DNS.
                  Please note that changes made on the NUM Server will take time
                  to propagate and we shall not be liable for any delay in that
                  propagation.
                </li>
              </ol>
            </li>
            <li>
              <strong>Delegating your Independent NUM Zone</strong>
              <ol>
                <li>
                  Subject to any Delegation Limits, you may delegate any
                  Independent NUM Zone to us. All NUM Records in that zone are
                  then Managed Records.
                </li>
                <li>
                  You may end the delegation of any Independent NUM Zone at any
                  time.
                </li>
              </ol>
            </li>
            <li>
              <strong>Populated Records</strong>
              <ol>
                <li>
                  We may create additional Hosted Records in the Hosted NUM Zone
                  (“Populated Records”) using external information we have
                  obtained by automatic means, for example web scraping. We do
                  this in order to increase the usefulness of the NUM protocol
                  for the benefit of those making NUM lookups. The creation of
                  Populated Records is not part of our service to you, and we
                  make no promises that:
                  <ol>
                    <li>
                      We will create any, or any particular, Populated Records;
                    </li>
                    <li>Populated Records will be accurate.</li>
                    <li>Populated Records will be served.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Managing Records</strong>
              <ol>
                <li>
                  For any Hosted NUM Records relating to a NUM ID you have
                  successfully claimed:
                  <ol>
                    <li>You may edit or delete any Managed Record.</li>
                    <li>
                      You may edit any Populated Record, but if you do so it
                      becomes a Managed Record.
                    </li>
                    <li>You may delete any Populated Record.</li>
                    <li>
                      You may mark an “Online” Managed Record as “Offline” and
                      vice versa, provided the number of Managed Records marked
                      as “Online” does not exceed the Record Limit for your
                      subscription.
                    </li>
                  </ol>
                </li>
                <li>
                  For the purposes of this agreement, a NUM ID is successfully
                  claimed, if you have claimed it using our service and have
                  verified it as belonging to you after you have presented us
                  with any evidence that we may request.
                </li>
                <li>
                  It is possible (for example where you have permitted your
                  domain name to be administered by more than one person) for
                  more than one person to successfully claim a NUM ID. It is
                  your responsibility to ensure that only those people you wish
                  to be able to edit or delete NUM Records for a NUM ID have the
                  ability to verify that the NUM ID belongs to them. We cannot
                  be responsible for inconsistencies arising under those
                  circumstances.
                </li>
              </ol>
            </li>
            <li>
              <strong>Deletion and Suspension of Records</strong>
              <ol>
                <li>
                  If we believe that the publication of a NUM Record is illegal
                  or unlawful or would otherwise compromise the running of our
                  business, we may mark that record as “Suspended”.
                </li>
                <li>
                  If we believe that the serving of NUM Records for any record
                  in a particular NUM Zone is illegal or unlawful or would
                  otherwise compromise the running of our business, we may
                  suspend serving any record for that NUM ID.
                </li>
                <li>
                  Where a single NUM Record has been marked as “Suspended” or
                  where we have suspended service of records for an entire NUM
                  ID, we will:
                  <ol>
                    <li>
                      Take reasonable steps to inform you if the suspension
                      relates to your NUM ID;
                    </li>
                    <li>
                      Resume service, or mark the record as Online (as the case
                      may be) on receiving credible evidence that the conclusion
                      we based our decision to suspend was incorrect.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Content of records</strong>
              <ol>
                <li>
                  Where any Managed Record contains personal data, Appendix A
                  (data processing) shall apply to our processing of that
                  Managed Record.
                </li>
                <li>
                  Where you include any personal data in a NUM Record, you
                  warrant that it is lawful for you to do so.
                </li>
              </ol>
            </li>
            <li>
              <strong>Fees and subscription</strong>
              <ol>
                <li>
                  The fee payable by you will depend on the tier you have
                  selected, which in turn will affect your Record Limit and
                  Delegation Limit. You may change your tier at any time, with
                  the change taking effect at the next monthly renewal. From
                  which point, unless and until you change your tier again, the
                  appropriate fee will be payable and the new limits will take
                  effect. You may change your tier to the “free” tier, providing
                  you are within the limits of that tier and no fees will be
                  payable.
                </li>
                <li>
                  The scale of fees and subscription limits will be that
                  advertised by us on our website at the time this agreement
                  begins. We may, from time to time, introduce a new scale of
                  fees, which will apply to you after it takes effect. We will
                  always give you at least 2 months’ notice before any new scale
                  of fees take effect.
                </li>
                <li>
                  All advertised fees are exclusive of VAT or any other sales or
                  analogous tax that may be payable.
                </li>
              </ol>
            </li>
            <li>
              <strong>Payment and non-payment</strong>
              <ol>
                <li>
                  We will take payment monthly, in advance, using the payment
                  method you have registered with us.
                </li>
                <li>
                  If we are unable to collect payment according to your selected
                  Plan, we will email you to inform you of the problem. If we
                  are still unable to collect payment after 7 days of the due
                  date, we will treat you as having a free account, and
                  accordingly mark all but the oldest 5 NUM Records in your NUM
                  Zone (if any) as Suspended. On payment, the records that were
                  suspended as a result of non-payment will be automatically set
                  to Online.
                </li>
              </ol>
            </li>
            <li>
              <strong>Duration and termination</strong>
              <ol>
                <li>
                  This agreement will start on the date on which you first setup
                  a user account and renew from month to month thereafter until
                  it is terminated in accordance with this agreement.
                </li>
                <li>
                  Either party may terminate this agreement by giving the other
                  at least one month’s notice, with termination taking place at
                  the end of a monthly renewal, except that for a free
                  subscription, either party may terminate this agreement at any
                  time.
                </li>
              </ol>
            </li>
            <li>
              <strong>Intellectual Property</strong>
              <ol>
                <li>
                  Nothing in this agreement shall be interpreted as an
                  assignment of any rights (including intellectual property
                  rights).
                </li>
                <li>
                  We grant you a non-exclusive licence, limited to the duration
                  of this agreement, to use the NUM Protocol to the extent
                  necessary to enjoy your rights under this agreement.
                </li>
              </ol>
            </li>
            <li>
              <strong>Limitation of liability</strong>
              <ol>
                <li>
                  Our cumulative maximum liability to you under or in connection
                  with this licence shall, irrespective of the basis of the
                  claim, be capped to an amount equal to the fees paid to us in
                  the preceding 12 month period.
                </li>
                <li>
                  We shall not be liable to you for any consequential, indirect
                  or special losses or for any of the following (whether direct
                  or indirect): loss of profit or savings, loss of opportunity
                  or contract, loss of reputation or good will, loss or damage
                  to equipment, loss or corruption of data, loss or corruption
                  of software or systems.
                </li>
                <li>
                  But, this agreement does not limit or exclude liability where
                  such limitation or exclusion would not be permitted by
                  applicable law.
                </li>
              </ol>
            </li>
            <li>
              <strong>Notices</strong>
              <ol>
                <li>
                  Any notices that need to be sent under this agreement shall be
                  written in English, sent by email to the receiving party’s
                  email address and deemed received when the receiving party’s
                  email server transmits a success code to the sending party.
                  Our email address is
                  <a href="mailto:terms@numserver.com">terms@numserver.com</a>.
                  Your email address is the most recent one you have entered on
                  our system through your account.
                </li>
              </ol>
            </li>
            <li>
              <strong>Law and jurisdiction</strong>
              <ol>
                <li>
                  This agreement shall be governed by English law and the courts
                  of England and Wales shall have jurisdiction to settle any
                  disputes arising in connection with it.
                </li>
              </ol>
            </li>
            <li>
              <strong>Definitions</strong>
              <ol>
                <li>
                  <span id="terms-description"
                    >In this agreement, the terms listed on the left hand column
                    of the table below have the meanings given in the right hand
                    column:</span
                  >
                  <div class="table-wrapper">
                    <table
                      style="
                        margin-top: 10px;
                        border-collapse: collapse;
                        width: 100%;
                      "
                      class="padded-table"
                    >
                      <tbody>
                        <tr>
                          <th scope="row">DNS</th>
                          <td>The Domain Name System</td>
                        </tr>
                        <tr>
                          <th scope="row">NUM Protocol</th>
                          <td>
                            The Namespace Utility Modules protocol described at
                            <a
                              href="https://www.numprotocol.com/specification"
                              target="_blank"
                              rel="noopener"
                              >https://www.numprotocol.com/specification</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Independent NUM Zone</th>
                          <td>A DNS domain with leftmost label “_num”</td>
                        </tr>
                        <tr>
                          <th scope="row">Hosted NUM Zone</th>
                          <td>Any subdomain of num.net</td>
                        </tr>
                        <tr>
                          <th scope="row">NUM ID</th>
                          <td>A domain name or email address</td>
                        </tr>
                        <tr>
                          <th scope="row">NUM Zone</th>
                          <td>
                            The Hosted NUM Zone and Independent NUM Zone taken
                            together
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Record Limit</th>
                          <td>
                            The limit on the number of Hosted NUM Records for
                            your subscription that we serve as set out in the
                            scale of fees.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Delegation Limit</th>
                          <td>
                            The limit on the number of NUM IDs that can be
                            delegated to the NUM Server for your subscription as
                            set out in the scale of fees.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { Container, Hero } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Container,
    Hero,
  },
});
</script>

<style lang="scss">
.table-wrapper {
  overflow-x: scroll;
  @apply -ml-4;
}

.padded-table {
  th,
  td {
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-gray-400;
  }
}
</style>
