<template>
  <div>
    <div class="last:mb-0">
      <slot v-if="toggled" />
      <p class="cursor-pointer" v-else @click="() => (toggled = true)">
        {{ toggleText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    toggleText: String,
  },
  data() {
    return {
      toggled: false,
    };
  },
});
</script>
