<template>
  <div>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose mx-auto">
          <h1 class="h3 text-gray-400 mb-2">Get started for free</h1>
          <h2 class="h1">Create your first NUM record in minutes</h2>
          <p>
            It's free to manage up to 50 NUM records, why not give it a try
            today?
          </p>
          <Button
            class="mt-4"
            href="https://app.numserver.com/tools/editor/add"
            target="_blank"
          >
            Give it a try
          </Button>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { Button, Container } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Button,
    Container,
  },
});
</script>
