<template>
  <div>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose mx-auto">
          <h1 class="h3 text-gray-400 mb-2">Websites are built for browsing</h1>
          <h2 class="h1">
            Apps, assistants and vehicles can't understand your website
          </h2>

          <p>
            Your company website is designed for your customers to browse like a
            brochure or catalogue, but the way your customers find your
            information is changing, fast. They're finding it through apps,
            assistants, even vehicle infotainments systems. Until now, only the
            gatekeepers of the web have been able to provide this data to your
            customers.
          </p>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { Container } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Container,
  },
});
</script>
