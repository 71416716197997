<template>
  <GlobalNav class="num-server-global-nav with-transparent-bg">
    <template v-slot:logo>
      <NumServerLogo />
    </template>
    <template v-slot:main-links>
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span>Overview</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'claim' }">
            <span>Claim</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'pricing' }">
            <span>Pricing</span>
          </router-link>
        </li>
        <li>
          <a href="https://app.numserver.com/login">
            <span>Login</span>
          </a>
        </li>
        <li>
          <a href="https://app.numserver.com/register">
            <span>Register</span>
          </a>
        </li>
      </ul>
    </template>
  </GlobalNav>
</template>

<script lang="ts">
import { GlobalNav } from "@num/component-library";
import Vue from "vue";

import NumServerLogo from "@/assets/images/logos/numserver.svg";

export default Vue.extend({
  components: {
    GlobalNav,
    NumServerLogo,
  },
});
</script>

<style lang="scss">
.num-server-global-nav {
  &.num-server-global-nav {
    --global-nav-theme-rgb: 55, 65, 81;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
  }
}
</style>
