import Vue from "vue";
import VueMeta from "vue-meta";

import App from "@/App.vue";
import router from "@/router";

import { STAGE } from "@/app.config";

import {
  directivesClickOutside,
  directivesScroll,
  utilsConfigureValidation,
} from "@num/component-library";
const configureValidation = utilsConfigureValidation.default;

configureValidation();

Vue.config.devtools = STAGE !== "prod";
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];
Vue.directive("click-outside", directivesClickOutside);
Vue.directive("scroll", directivesScroll);

Vue.use(VueMeta);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

document.body.addEventListener("keyup", (event) => {
  const isTabKey = event.which === 9;
  // @ts-expect-error: for some reason form doesn't exist on target but it works
  const eventInForm = event?.target?.form;

  if (isTabKey && !eventInForm) {
    document.documentElement.classList.remove("no-focus-outline");
  }
});
