<template>
  <div>
    <Hero />
    <BuiltForMachines />
    <TakeControl />
    <NumExplainer />
    <GetStarted />
  </div>
</template>

<script>
import Vue from "vue";

import BuiltForMachines from "@/views/Home/BuiltForMachines.vue";
import GetStarted from "@/views/Home/GetStarted.vue";
import Hero from "@/views/Home/Hero.vue";
import NumExplainer from "@/components/NumExplainer.vue";
import TakeControl from "@/views/Home/TakeControl.vue";

export default Vue.extend({
  name: "Home",
  components: {
    BuiltForMachines,
    GetStarted,
    Hero,
    NumExplainer,
    TakeControl,
  },
});
</script>
