<template>
  <div id="app" class="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Vue from "vue";

import NumServerGlobalFooter from "@/components/NumServerGlobalFooter.vue";
import NumServerGlobalNav from "@/components/NumServerGlobalNav.vue";

import DefaultLayout from "@/layouts/Default";

export default Vue.extend({
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Webhosting, for NUM",
    // all titles will be injected into this template
    titleTemplate: "%s | NUM Server",
  },
  components: {
    NumServerGlobalNav,
    NumServerGlobalFooter,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || DefaultLayout;
    },
  },
});
</script>

<style lang="scss">
@import "~@num/component-library/dist/component-library.esm.css";
@import "~@/assets/styles/fonts.scss";
@import "~@/assets/styles/base.scss";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* without this we cut off the nav dropdown on pages with minimal content */
  overflow: hidden; /* needed to stop diagonal footer from showing below page */
}
</style>
