<template>
  <div>
    <Hero class="bg-dark-gray-gradient-angle-bottom">
      <h1 class="fs-0">Pricing</h1>
    </Hero>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose">
          <div class="p-4 md:p-8 bg-white rounded-lg last:mb-0 mb-8 lg:mb-12">
            <div class="mb-4 lg:mb-8">
              <h2 class="fs-1">
                Free
                <span class="fs-2 text-gray-400"
                  >&ndash; Early adopter plan</span
                >
              </h2>
              <p>
                All those who sign up during our initial launch get up to
                <strong>50 hosted NUM records &amp; 5 delegated domains</strong>
                free of charge.
              </p>
            </div>
            <Button href="https://app.numserver.com/register">
              Sign up for free
            </Button>
          </div>
        </div>
        <table
          style="margin-top: 10px; border-collapse: collapse; width: 100%"
          class="padded-table mb-4 lg:mb-8"
        >
          <tbody>
            <tr>
              <th class="bg-gray-200">Price (/month)*</th>
              <th class="bg-gray-200">NUM records</th>
              <th class="bg-gray-200">Delegated domains</th>
            </tr>
            <tr>
              <td>Free</td>
              <td>50</td>
              <td>5</td>
            </tr>
            <tr>
              <td>£9.50</td>
              <td>100</td>
              <td>10</td>
            </tr>
            <tr>
              <td>£17</td>
              <td>200</td>
              <td>20</td>
            </tr>
            <tr>
              <td>£22</td>
              <td>300</td>
              <td>30</td>
            </tr>
            <tr>
              <td>£27</td>
              <td>450</td>
              <td>45</td>
            </tr>
            <tr>
              <td>£32</td>
              <td>600</td>
              <td>60</td>
            </tr>
            <tr>
              <td>£36</td>
              <td>750</td>
              <td>75</td>
            </tr>
            <tr>
              <td>£42</td>
              <td>1000</td>
              <td>100</td>
            </tr>
            <tr>
              <td>£54</td>
              <td>1500</td>
              <td>150</td>
            </tr>
            <tr>
              <td>£60</td>
              <td>2000</td>
              <td>200</td>
            </tr>
          </tbody>
        </table>
        <small class="text-gray-500"
          ><em
            >* Cost excludes VAT, charged at 20% for customers based in the UK
            and customers in the EU without an EU VAT ID. There's no VAT charged
            to customers outside the EU.</em
          ></small
        >
      </Container>
    </div>
  </div>
</template>

<script>
import { Button, Container, Hero } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Button,
    Container,
    Hero,
  },
});
</script>
