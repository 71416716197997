<template>
  <div>
    <div class="py-12 md:py-24">
      <Container>
        <div class="max-w-prose mx-auto">
          <h1 class="h3 text-gray-500 mb-2">Own your data</h1>
          <h2 class="h1">Publish 50 records for 5 domains for free</h2>
          <p>
            Adopting the NUM protocol independently requires some technical
            knowledge and so it isn't for everyone.
          </p>
          <p>
            But at NUM we believe everyone should control their own data, that's
            why we let you publish up to 50 records for 5 different domains
            entirely free with the NUM Server.
          </p>
          <Button :href="claimUrl" target="_blank">
            <template v-if="numId"
              >Claim <code>{{ numId }}</code> for free</template
            >
            <template v-else>Sign up for free</template>
          </Button>
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { Button, Container } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Button,
    Container,
  },
  props: {
    claimUrl: String,
    numId: String,
  },
});
</script>
